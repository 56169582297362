@media (orientation: portrait) {
  html {
    overflow-y: hidden;
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  /*line-height:30px;*/
  font-weight: 400;
  font-size: 14px;
  /*margin-right:-15px*/
  background-size: cover;
  background-position: top center;
  background-image: url('background.png');
  background-color: #000000;
}
a,
a:visited,
a:hover {
  color: #ababab;
}
h2,
.page h2.ui.header,
.ui.modal > .header:not(.ui) {
  font-size: 1.4rem !important;
}
h2 {
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 5px;
}
[type='checkbox'] {
  width: 20px;
  height: 20px;
}
.App {
  text-align: center;
  padding-top: 0px;
  height: 100%;
  /*height: 98vh;*/
}
.footer {
  margin-top: 20px;
  color: grey;
}
.footer img {
  height: 25px;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5250ms ease-in;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.ui.text.container {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
}
.card-red {
  background-image: radial-gradient(circle, #fd3d51, #c50518) !important;
}
.card-green {
  background-image: radial-gradient(circle, #8fce4b, #478209);
}
.card-white {
  background-image: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
}
.card-dark {
  background-color: rgba(0, 0, 0, 0.6);
}
.color-white {
  color: #ffffff !important;
}
.color-grey {
  color: grey !important;
}
.color-black {
  color: #000000 !important;
}
.color-green {
  color: #089208 !important;
}
.color-red {
  color: #be0a0a !important;
}
.card {
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1) !important;
}
.react-bootstrap-table {
  margin-top: 10px;
}
.react-bootstrap-table .advice.float-right {
  margin-left: 12px;
}
.react-bootstrap-table .table {
  width: 100%;
  table-layout: auto;
  border-spacing: 0;
  border-collapse: collapse;
}
.react-bootstrap-table .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
}
.react-bootstrap-table .table > tbody > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
}
.top-10 {
  margin-top: 10px !important;
}
.top-15 {
  margin-top: 15px !important;
}
.top-20 {
  margin-top: 20px !important;
}
.top-30 {
  margin-top: 30px !important;
}
.top-40 {
  margin-top: 40px !important;
}
.top-50 {
  margin-top: 50px !important;
}
.top-100 {
  margin-top: 100px !important;
}
.top-5 {
  margin-top: 5px !important;
}
.left-10 {
  margin-left: 10px !important;
}
.bottom-5 {
  margin-bottom: 5px !important;
}
.bottom-10 {
  margin-bottom: 10px !important;
}
.bottom-20 {
  margin-bottom: 20px !important;
}
.bottom-30 {
  margin-bottom: 30px !important;
}
.bottom-40 {
  margin-bottom: 40px !important;
}
.pad-top-15 {
  padding-top: 15px !important;
}
.pad-bottom-10 {
  padding-bottom: 10px !important;
}
.pad-top-5 {
  padding-top: 5px !important;
}
.pad-bottom-5 {
  padding-bottom: 5px !important;
}
.pad-none {
  padding: 0px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.bold {
  font-weight: 500;
}
.bold.spaced {
  letter-spacing: 0.15rem;
}
i.transparent {
  color: transparent !important;
}
.main-menu.ui.menu {
  background-position: top center;
  background-image: url('background.png');
}
.main-menu.ui.menu.bottom {
  background-position: bottom center;
  border-top: 1px solid grey;
  padding-bottom: 10px;
  padding-top: 5px;
  /*
http://stephen.io/mediaqueries/
iPhone X in portrait & landscape
*/
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .main-menu.ui.menu.bottom {
    padding-bottom: 25px;
  }
}
.main-menu.ui.menu .app-logo {
  height: 36px;
}
.main-menu.ui.menu .item {
  padding: 5px 0 5px 0;
  border: 0 !important;
}
.main-menu.ui.menu .item:before {
  background: transparent !important;
}
.main-menu.ui.menu .item .icon {
  margin: 0;
  color: #b4b4b4;
}
.main-menu.ui.menu .item.active,
.main-menu.ui.menu .item.active:hover {
  background: inherit;
  border-bottom: 1px solid red !important;
}
.main-menu.ui.menu .ui.floating.label {
  top: 0.8em;
  left: 95%;
  font-size: 0.7rem;
}
.app-button {
  color: white !important;
  background-image: linear-gradient(to bottom, #6b6a6a 0%, #373737 100%) !important;
}
.app-button.green {
  background-image: linear-gradient(to bottom, #00be00 0%, #006100 100%) !important;
}
.app-button .icon {
  color: white;
}
.perfs-container .ui.ribbon.label {
  left: calc(-7rem - 1.3em);
}
.perfs-container .perfs-input-container .ui.ribbon.label {
  left: calc(-5rem - 1.4em);
}
.perfs-container .perfs-input-container .card {
  background: rgba(0, 0, 0, 0.6) !important;
  border: 1px solid grey;
}
.perfs-container .ui.card.perf-display {
  border: 1px solid grey;
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff;
}
.perfs-container .ui.card.perf-display .meta {
  color: #d2d2d2;
}
.perfs-container .ui.card.perf-display .description {
  color: #d2d2d2;
}
.ui.inverted.list .item a:not(.ui):hover {
  color: #ababab !important;
}
.ui.inverted.list .item a:not(.ui) {
  color: #ababab !important;
}
div.main-content {
  height: 100%;
}
.full-page {
  padding-top: 10px;
}
.full-page .event .content {
  margin-bottom: 0px !important;
}
.full-page .event .content .date {
  color: #d4d4d5 !important;
}
.full-page .event .content .summary {
  color: #fff !important;
}
.full-page .event .content .extra.text {
  color: #fff !important;
}
.page {
  color: white;
}
.page .header {
  padding-top: 5px;
  color: white;
  font-weight: 500;
  /* text-transform: uppercase;*/
  text-transform: capitalize;
}
.page .header.no-capitalize {
  text-transform: unset;
}
.page .segment {
  white-space: pre-line;
}
.page .right.floated {
  float: right;
  margin-bottom: 1em;
  margin-right: 0.2em;
  margin-top: 0.2em;
}
.page .left.floated {
  float: left;
  margin-bottom: 1em;
  margin-left: 0.2em;
  margin-top: 0.2em;
}
.page .card-dark {
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.28571429rem;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid grey !important;
}
.page .card-dark .card .header {
  color: white !important;
}
.page .analysis .meta {
  color: grey;
}
.page .analysis .description {
  color: white;
}
.page .ui.selection.dropdown {
  min-width: 9.2em;
}
div.signup-form,
div.login-form,
div.pending-container {
  height: 100vh;
}
.login-form a,
.pending-container a,
.signup-form a {
  font-size: 1em;
  color: #aaa;
}
.modal .description,
.ui.card > .content,
.content {
  white-space: pre-line;
}
.ui.fluid.container.pushable {
  margin: 0px 0px 45px 0px !important;
}
.training-log .form textarea,
.training-log .form textarea:focus {
  background-color: rgba(0, 0, 0, 0.32);
  color: rgba(255, 255, 255, 0.43);
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.training-log .card {
  /*	border-top: 1px solid grey;*/
  background: transparent;
  color: #fff;
}
.training-log .card .header,
.training-log .card .content {
  color: #fff !important;
}
.training-log .card .header .description,
.training-log .card .content .description {
  color: #fff !important;
  padding: 0;
}
.sidebar.ui.inverted.icon.menu .item {
  color: grey;
}
.hidden {
  display: none !important;
}
.ui.hidden.divider {
  display: block;
  border-color: transparent !important;
}
.Toastify__toast-container {
  top: 52px !important;
}
.Toastify__toast-container .Toastify__toast--error {
  background: #db2828;
}
.Toastify__toast-container .Toastify__toast--default {
  background: #0f1b29;
  color: #aaa;
  border: 1px solid #aaa;
}
.Toastify__toast-container .Toastify__progress-bar--default {
  background: #6b6a6a;
}
.Toastify__toast-container .Toastify__close-button--default {
  color: #fff;
}
.riek {
  border: 1px solid #d3d3d39e;
}
.ui.dark.card {
  background-color: transparent;
}
.ui.dark.card > .content {
  background-color: rgba(0, 0, 0, 0.23) !important;
}
.ui.dark.card > .content .header {
  color: white;
}
.ui.dark.card > .content .description {
  clear: both;
  color: white;
}
@media only screen and (max-width: 767px) {
  .ui.dark.card.drill-analysis .content .description.left-10 {
    margin-left: 10px !important;
  }
}
.avatar-upload {
  /* margin-top: -50px;*/
}
.avatar-upload .ui.button {
  vertical-align: baseline;
  background: rgba(0, 0, 0, 0.8);
  background: transparent;
  color: #e0e1e2;
}
.avatar-upload .ui.button > .icon {
  margin: 0 !important;
}
.avatar-upload .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.avatar-upload .loader {
  margin-top: 10px;
}
.profilePic-placeholder {
  z-index: 100;
  position: relative;
  min-height: 90px;
}
.profilePic-placeholder .profile-image {
  width: 80px;
  border-radius: 170px;
  /* margin: 20px auto 0px auto;*/
  border: solid 0px #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}
.actions-placeholder .call-to-action {
  width: 312px;
  margin: 0px auto 20px auto;
  height: 80px;
}
.actions-placeholder .call-to-action .action {
  width: 80px;
  height: 80px;
  float: left;
  position: relative;
  z-index: 100;
  /*        background-color: #FFFFFF;*/
  background-image: linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%);
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
.actions-placeholder .call-to-action .action > i {
  position: absolute;
  top: 20px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 20px;
  /*color: #383838;*/
  color: #ffffff;
}
.actions-placeholder .call-to-action .action .label > .icon {
  margin: 0;
}
.actions-placeholder .call-to-action .action.highlight {
  background-image: linear-gradient(to bottom, #ef0202 0%, #720000 100%);
}
.actions-placeholder .call-to-action .action.disabled {
  opacity: 0.45;
}
.actions-placeholder .call-to-action em {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a8a8a8;
  border-top: solid 1px rgba(0, 0, 0, 0.03);
  line-height: 12px;
}
.actions-placeholder .call-to-action .icongroup .corner.icon {
  top: 10px;
  left: 10px;
  font-size: 0.45em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.Home {
  height: 100%;
  padding-top: 20px;
}
.Home .home-header {
  box-shadow: none !important;
  max-width: 400px;
}
.Home .home-header h2 {
  font-size: 21px;
}
.Home .home-header .card {
  box-shadow: none !important;
}
.Home .home-header .description .column {
  margin-left: 0;
  margin-right: 0;
}
.Home .home-header .name-placeholder {
  text-align: right;
  padding-top: 4rem !important ;
  padding-right: 10% !important;
}
.Home .home-header .name-placeholder h2 {
  margin-top: 0px;
}
.Home .home-header .card {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  line-height: normal;
  padding: 0px 0px;
  background: transparent;
}
.Home .home-header .card.inner {
  padding: 2px;
  margin-top: 0;
  margin-bottom: 0;
}
.Home .home-header .card .advice {
  margin: 0 5px;
  text-align: justify;
  text-overflow: ellipsis;
  border-bottom: 1px solid red;
  font-size: 13px;
}
.Home .home-header .card h4 {
  margin: 5px;
}
.Home .home-header .card > .content > .header:not(.ui),
.Home .home-header .card .ui.cards > .card > .content > .header:not(.ui) {
  font-weight: 700;
  font-size: 1.1em;
  margin-top: -0.21425em;
  line-height: 1.18571429em;
}
.Home .home-header .card .meta {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.7);
}
.Home .home-header .card > .content > .meta + .description {
  margin-top: 0.5em;
  white-space: pre-line;
}
.Home .card .meta + .description {
  margin-top: 0.5em;
  white-space: pre-line;
  line-height: normal;
  padding-bottom: 10px;
}
.Home .toggleable {
  min-width: 318px;
  top: 15px;
  padding: 10px;
}
.Home .toggleable .next-class .description {
  text-align: left;
  padding-left: 15px;
  padding-right: 5px;
}
.Home .toggleable .next-class,
.Home .toggleable .card-dark {
  margin-top: 30px;
}
.Home .toggleable .timesheet .header {
  color: #ffffff !important;
}
.Home .call-to-action {
  width: 312px;
  margin: 0px auto 20px auto;
  height: 80px;
}
.Home .call-to-action .action {
  width: 80px;
  height: 80px;
  float: left;
  position: relative;
  z-index: 100;
  /*        background-color: #FFFFFF;*/
  background-image: linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%);
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
.Home .call-to-action .action > i {
  position: absolute;
  top: 20px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 20px;
  /*color: #383838;*/
  color: #ffffff;
}
.Home .call-to-action .action .label > .icon {
  margin: 0;
}
.Home .call-to-action .action.highlight {
  background-image: linear-gradient(to bottom, #ef0202 0%, #720000 100%);
}
.Home .call-to-action .action.disabled {
  opacity: 0.45;
}
.Home .call-to-action em {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a8a8a8;
  border-top: solid 1px rgba(0, 0, 0, 0.03);
  line-height: 12px;
}
.Home .call-to-action .icongroup .corner.icon {
  top: 10px;
  left: 10px;
  font-size: 0.45em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.Home .right.floated {
  float: right;
  margin-right: 0;
  margin-bottom: 1em;
  margin-right: 0.2em;
  margin-top: 0.2em;
}
.Home .right.floated.no-margin {
  margin: 0;
}
